import React, { useState, useEffect, useContext, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, Outlet } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import NotFound from "pages/NotFound";
import { Img } from "components";
import { OfferrideProvider } from "contexts/OfferrideContext";
import useNetworkStatus from "contexts/useNetwork";
import NetworkStatusOverlay from "components/NetworkStatusOverlay";
import useCompleteRegistration from "contexts/useCompleteRegistration";
import AdditionalInfoMModal from "components/AdditionalInfoModal";
import CookieConsent,  { resetCookieConsentValue } from "react-cookie-consent";
import { Link } from "react-router-dom";
const FrameFourteen = React.lazy(() => import("pages/FrameFourteen"));
const OfferaRidePage = React.lazy(() => import("pages/OfferaRide"));
const Profile = React.lazy(() => import("pages/Profile"));
const BookRide = React.lazy(() => import("pages/BookRide"));
const FrameTwelve = React.lazy(() => import("pages/FrameTwelve"));
const FindaRidePage = React.lazy(() => import("pages/FindaRide"));
const FAQ = React.lazy(() => import("pages/FAQ"));
const Accountsettings = React.lazy(() => import("pages/Accountsettings"));
const Personaldetails = React.lazy(() => import("pages/Personaldetails"));
const SignUpPage = React.lazy(() => import("pages/SignUpPage"));
const SigninPage = React.lazy(() => import("pages/SigninPage"));
const ContactUs = React.lazy(() => import("pages/ContactUs"));
const EmailConfirmationPage = React.lazy(() => import("pages/Emailconfirmation"))
const LandingPage = React.lazy(() => import("pages/LandingPage"));
const YourRidesPage = React.lazy(() => import("pages/YourRides"))
const PostRidePage = React.lazy(() => import("pages/PostRide"));
const TrackRide = React.lazy(() => import("pages/TrackYourRide"));
const PublishedRidePage = React.lazy(() => import("pages/PublishedRide"));
const ManageRidePage = React.lazy(() => import("pages/ManageRide"));
const ResetPassEmailConfirmPage = React.lazy(() => import("pages/ResetEmailConfirmation"));
const ResetPassLinkSentPage = React.lazy(() => import("pages/ResetPasswordLinkSent"));
const ResetPasswordPage = React.lazy(() => import("pages/ResetPassword"));
const PrivacyPolicyPage = React.lazy(() => import("pages/PrivacyPolicy"));
const RidePaymentPage = React.lazy(() => import("pages/RidePayment")); 
const RideBookingStatus = React.lazy(() => import("pages/RideBookingStatus"));
const RateRide = React.lazy(() => import("pages/RateRide"));
const LiveLocation = React.lazy(() => import("pages/LIveLocation"))
const MapNavigation = React.lazy(() => import("pages/NavigationPage"));

function PrivateRoute({  }) {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  const navigate = useNavigate();
 
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate('/signinpage');
    }
  }, [isAuthenticated, isLoading, navigate]);;
   
  if (isLoading) {
    return <><div className="flex items-center justify-center w-full h-[100vh]"><svg aria-hidden="true" role="status" className="inline w-[100px] h-[100px]  text-[#2788e6] animate-spin" viewBox="0 0 100 101" fill="#2788e6" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
    </svg></div></>;
  }

  return isAuthenticated ? <Outlet /> : null;
}


const ProjectRoutes = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { isAuthenticated} = useContext(AuthContext);
  const { refreshAuthentication } = useContext(AuthContext);
  const isOnline = useNetworkStatus();
  const openModal = useCompleteRegistration();

  

  useEffect(() => {
    setIsOpenModal(openModal);
  }, [openModal]);
 



  const handleRetry = useCallback(() => {
    window.location.reload();
  }, []);

   return (
  <React.Suspense fallback={<><div className="flex items-center justify-center w-full h-[100vh]"><svg aria-hidden="true" role="status" className="inline w-[100px] h-[100px]  text-[#2788e6] animate-spin" viewBox="0 0 100 101" fill="#2788e6" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
    </svg></div></>}>
      <Router>
      <NetworkStatusOverlay isOnline={isOnline} retryAction={handleRetry} />
      <AdditionalInfoMModal
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
           
          />
      <OfferrideProvider>
        <Routes>
        <Route path="/" element={<LandingPage />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/signuppage" element={<SignUpPage />} />
          <Route path="/signinpage" element={<SigninPage />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/findaride" element={<FindaRidePage />} />
          <Route path="/frameTwelve" element={<FrameTwelve />} />
          <Route path="/emailconfirmation" element={<EmailConfirmationPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
          <Route path="/offeraride" element={<OfferaRidePage />} />
          <Route path="/yourrides" element={<YourRidesPage />} />
          <Route path="/resetpassemailconfirm" element={<ResetPassEmailConfirmPage />} />
          <Route path="/resetpasslinksent" element={<ResetPassLinkSentPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/rateRide/:rideId" element={<RateRide />} />
          <Route path="/livelocation" element={<LiveLocation />} />
          <Route path="/mapnavigation" element={<MapNavigation />} />
          
          
              {/*Protected routes*/}
             
          <Route path="/profile" element={<PrivateRoute />}>
          <Route index element={<Profile />} />
          </Route>
          <Route path="/bookride/:rideId" element={<PrivateRoute />}>
            <Route index element={<BookRide />} />
          </Route>
          <Route path="/ridepayment/:rideId" element={<PrivateRoute />}>
            <Route index element={<RidePaymentPage />} />
          </Route>
          <Route path="/accountsettings" element={<PrivateRoute />}>
            <Route index element={<Accountsettings />} />
          </Route>
          <Route path="/personaldetails" element={<PrivateRoute />}>
          <Route index  element={<Personaldetails /> } />
          </Route>
          <Route path="/frameFourteen" element={<PrivateRoute />}>
            <Route index element={<FrameFourteen />} />
          </Route>
          <Route path="/trackride" element={<PrivateRoute />}>
            <Route index element={<TrackRide />} />
          </Route>
          <Route path="/publishedride" element={<PrivateRoute />}>
            <Route index element={<PublishedRidePage />} />
          </Route>
          <Route path="/manageride/:rideId" element={<PrivateRoute />}>
            <Route index element={<ManageRidePage />} />
          </Route>
          <Route path="/postride" element={<PrivateRoute />}>
            <Route index element={<PostRidePage />} />
          </Route>
          <Route path="/ridebookingstatus" element={<PrivateRoute />}>
            <Route index element={<RideBookingStatus />} /> 
          </Route>
          
        
          
          <Route path="*" element={<NotFound />} />
        </Routes>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="myCookieConsent"
          style={{ background: "#3B82F6", }} // Tailwind color for 'gray-800'
          buttonStyle={{ 
          background: "#1D4ED8", // Tailwind color for 'blue-600'
          color: "#ffffff", 
          padding: "0.5rem 1rem",
          borderRadius: "0.25rem" // Tailwind class equivalent to rounded
        }}
        declineButtonStyle={{
          background: "#EF4444", // Tailwind color for 'red-500'
          color: "#ffffff",
          padding: "0.5rem 1rem",
          borderRadius: "0.25rem" // Tailwind class equivalent to rounded
        }}
          expires={150}
          enableDeclineButton
          flipButtons
          onAccept={() => {
            console.log("Cookies Accepted");
          }}
          onDecline={() => {
            console.log("Cookies Declined");
          }}
        >
          <span className="text-sm">
            We use cookies to improve your experience. By accepting, you agree to our use of cookies.{" "}
            <Link to="/privacypolicy" className="text-blue-100 underline hover:text-blue-300 transition">
              Learn more
            </Link>
          </span>
        </CookieConsent>
        </OfferrideProvider>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
