import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'components';

function AdditionalInfoMModal({ isOpen, onClose,  }) {
  const closeModal = () => {
    console.log('closeModal called');
    onClose();
  };

  

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-gray-800 opacity-50 z-[60]" ></div>
      <dialog open className="fixed top-[50%] z-[70] p-8 w-1/3 transform -translate-y-1/2 rounded-lg shadow-md">
        <div className="text-center">
          <h1 className="text-2xl text-left mt">Complete Your Registration</h1>
           <div className="flex flex-col gap-3 mt-4">
          <input type='tel' placeholder='Phone Number' className="border border-gray-300_01 text-base  p-3 placeholder:text-base  rounded" />
          <input type="date" placeholder='Date of Birth' className="border border-gray-300_01  text-base  p-3 placeholder:text-base  rounded" />
          <input type='text' placeholder='Gender' className="border border-gray-300_01  text-base p-3 placeholder:text-base   rounded" />
          </div>
          <button className="bg-blue-600 px-4 py-3 mt-6 w-full rounded text-white-A700_01" onClick={closeModal}>Submit</button>
        </div>
      </dialog>
    </>
  );
}

AdditionalInfoMModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  
};

export default AdditionalInfoMModal;
