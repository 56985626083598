import React,{useState, useContext, useEffect} from "react";
import { Link,useNavigate } from "react-router-dom";
import { Img, Text, Button, Line } from "components";
import axios from "axios"
import { useToast } from "contexts/ToastContext";
import { AuthContext } from "contexts/AuthContext";

const Header = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showdropdown, setShowdropdown] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [profileData, setProfileData] = useState(null);
  
  const navigate = useNavigate()
  const { setShowToast } = useToast();

  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const { refreshAuthentication } = useContext(AuthContext);

  useEffect(() => {
    // This effect will trigger whenever isAuthenticated changes
    // Add any additional logic here
}, [isAuthenticated]);

useEffect(() => {
  let isMounted = true;

  const fetchProfileData = async () => {
    try {
      const response = await axios.get("/profile");
      if (isMounted) {
        if (response.data.photo !== null) {
        const relativePath = response.data.photo.split("http://localhost:3001")[1];
        setProfileData(relativePath);
        }
      }
    } catch (error) {
      if (isMounted) {
        console.error("Error fetching profile data:", error);
      }
    }
  };

  fetchProfileData();

  return () => {
    isMounted = false;
  };
}, []);
  

  const handleLogout = async () => {
    try {
      const response = await axios.post("/logout", null, {
        withCredentials: true, // Include cookies in the request
      });
      if (response.status === 200) {
        console.log("Logout successful");
        await refreshAuthentication();
        navigate('/')
        setShowToast(true); // sets the value of showToast to true
      } else {
        console.log("Logout failed");
        // Handle logout failure
      }
    } catch (error) {
      console.error("Error during logout:", error);
      // Handle error during logout
    }
  };

  const openConfirmationDialog = () => {
    setShowConfirmation(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmation(false);
  };

  const confirmLogout = () => {
    handleLogout();
    closeConfirmationDialog();
  };

  return (
    <>
     
      <header className="fixed z-[50] top-0 sm:border-0    border-b-gray-200  bg-white-A700  sm:px-5  md:px-5 md:pt-0 sm:pt-2.5 px-24 py-2.5    md:h-auto flex justify-between md:flex-col  md:justify-center  sm:flex-col flex-row sm:gap-5 items-center sm:justify-start  md:   w-full">
             <div className="flex  sm:justify-between py-[10px]   md:justify-center  sm:mt-0 md:mt-10 mt-0   sm:w-full "> 
            
            <Text
              className=" text-[24px] md:mb-0 sm:ml-0 md:ml-0 font-[450]    sm:text-[27.7px] md:text-[29.7px]  text-blue-700"
          
            >
              <Link to='/'>
              JoinMiCar</Link>
            </Text>
             
             <div className="hidden sm:flex sm:gap-2 sm:flex-row">
             <span className="relative  inline-flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" className="text-gray-700">
<path d="M15.9993 18.0009C15.9993 20.209 14.2084 21.999 11.9993 21.999C9.79019 21.999 7.99933 20.209 7.99933 18.0009" stroke="#141B34" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M19.5373 12.403V9.4938C19.5373 5.35563 16.179 2.00098 12.0364 2.00098C7.89384 2.00098 4.53559 5.35563 4.53559 9.4938V12.403L2.54039 15.8467C2.5174 15.8864 2.52266 15.9352 2.55605 15.9666C5.45881 18.6986 15.0953 21.1257 21.4338 15.9462C21.4712 15.9156 21.4815 15.8621 21.4578 15.82L19.5373 12.403Z" stroke="#141B34" strokeWidth="1.5"/>
</svg>
            <span className="flex absolute h-3 w-3 top-0 right-0 -mt-0 -mr-0">
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
      <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
    </span>
  </span>
             <Link to="/personaldetails" >
              <Img
              
          className={`"h-24 hidden sm:flex cursor-pointer  sm:w-[32px] sm:h-[32px] md:h-auto sm:ml-0 ml-[49px] sm:-mt-0  object-cover w-24 ${profileData && 'rounded-full w-16  mt-9 h-16'}`}
          src={profileData ? profileData :"/images/img_vectorflatill.png"}
          alt="profile"
        /> </Link>
             {showMenu ? (
            <svg onClick={() => setShowMenu(false)}
              id="close"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-7 h-7   sm:mr-0 cursor-pointer sm:flex hidden"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg onClick={() => setShowMenu(true)}
              id="burger"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-7 h-7 sm:mt-[2.5px] sm:mr-0 cursor-pointer sm:flex  hidden"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
)}            </div>
            </div>
            {showMenu && (
        <div onClick={() => setShowMenu(false)} className="fixed inset-0 bg-black-900 bg-opacity-50  z-[50]"></div>
      )}
            <div className={` ${showMenu ? '' : 'sm:hidden'}  sm:h-[100vh] md:py-0 py-[10px] sm:fixed sm:top-0 sm:right-0 sm:z-[70] sm:w-[70%] sm:bg-white-A700 sm:shadow-lg sm:transition-transform sm:duration-300   flex  flex-row sm:flex-col  md:flex  ` }>
            <Link to='/findaride' className="sm:mt-11  "><Text
              className=" sm:mb-0 sm:ml-4 ml-[px] sm:text-base md:mt-6 md:mb-0  sm:mt-0 mt-[10px] text-gray-800 text-sm sm:normal-case uppercase"
              size="txtRubikRegular14"
            >
              Find a Ride
            </Text></Link>
            <Link to='/offeraride'>
            <Text
              className=" sm:mb-0 sm:ml-4 ml-[49px] sm:text-base md:mb-0  md:mt-6 sm:mt-3 mt-[10px] text-gray-800 text-sm sm:normal-case uppercase"
              size="txtRubikRegular14"
            >
              Offer a Ride
            </Text></Link>
            
            <Button onClick={openConfirmationDialog}
              className="sm:hidden border sm:border-none md:h-10 md:mb-0 md: border-blue-700 border-solid px-4 my-0  py-[8px] md:px-3 md:py-2 rounded-md cursor-pointer sm:w-full  sm:ml-[0] ml-[49px] md:mr-0 sm:mt-0  md:mt-4 text-center text-sm uppercase"
              
              color="white_A700"
            >
              Log Out
            </Button>
            
            <span className="sm:hidden relative mt-[5px] md:mt-4 ml-[49px] inline-flex">
            <svg  onClick={() => { setShowNotification(!showNotification); setShowdropdown(false); 
                 
                }} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" className="cursor-pointer text-gray-700">
<path d="M15.9993 18.0009C15.9993 20.209 14.2084 21.999 11.9993 21.999C9.79019 21.999 7.99933 20.209 7.99933 18.0009" stroke="#141B34" strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M19.5373 12.403V9.4938C19.5373 5.35563 16.179 2.00098 12.0364 2.00098C7.89384 2.00098 4.53559 5.35563 4.53559 9.4938V12.403L2.54039 15.8467C2.5174 15.8864 2.52266 15.9352 2.55605 15.9666C5.45881 18.6986 15.0953 21.1257 21.4338 15.9462C21.4712 15.9156 21.4815 15.8621 21.4578 15.82L19.5373 12.403Z" stroke="#141B34" strokeWidth="1.5"/>
</svg>
            <span onClick={() => {setShowNotification(!showNotification); setShowdropdown(false)
                 
            }} className="cursor-pointer flex absolute h-3 w-3 top-0 right-0 -mt-0 -mr-0">
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
      <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
    </span>

    <div className={`md: sm:flex ${showNotification  ? '' : 'hidden'}  shadow-bs3 sm:static sm:shadow-none  absolute -right-10  z-10 sm:mt-6 mt-[60px] w-64   bg-white-A700  focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
    <div className="py-1" role="none">
    
      <Link to="" className="flex items-center px-4 py-3  transition-colors duration-300 transform border-b border-gray-100 hover:bg-gray-100">
                <img className="flex-shrink-0 object-cover w-9 h-9 mx-1 rounded-full" src='/images/face1.png' />
                <p className="mx-2 text-sm text-gray-600 dark:text-white"><span className="font-medium text-gray-800" >Joseph </span> requested to book your ride. 2m</p>
         </Link>
         <Link to="" className="flex items-center px-4 py-3  transition-colors duration-300 transform border-b border-gray-100 hover:bg-gray-100">
                <img className="flex-shrink-0 object-cover w-9 h-9 mx-1 rounded-full" src='/images/img_61.png' />
                <p className="mx-2 text-sm text-gray-600 dark:text-white"><span className="font-medium text-gray-800" >Joe </span> requested to book your ride. 2m</p>
         </Link>
      
    </div>
    <a href="#" className="block py-2 font-medium text-center text-base text-white bg-blue-600 text-white-A700_01 rounded-b-md hover:underline">See all notifications</a>
    </div>
      
  </span>

 

            <Link to="/personaldetails" >
              <Img
              
          className={`"h-9 sm:hidden cursor-pointer md:mt-4  sm:w-[28px] sm:h-[28px] md:h-8 md:mb-0 md:w-8 sm:ml-[0] ml-[16px] sm:mt-0 mt-[0px] object-cover w-9 ${profileData && 'rounded-full w-9  mt-9 h-9'}`}
          src={profileData ? profileData :"/images/img_vectorflatill.png"}
          
        /> </Link>
         <div className="relative inline-block text-left">
        <Img
          className="h-6 cursor-pointer sm:hidden sm:ml-[0] ml-[13px]  sm:mt-0 md:mr-0 md:mt-6  mt-[10px] w-6"
          src="/images/img_arrowdown.svg"
          alt="arrowdown"
          id="menu-button"
          onClick={() => {  setShowdropdown(!showdropdown) ;setShowNotification(false)
           
          }}
        />
         <div className={`md: sm:flex ${showdropdown ? '' : 'hidden'}  shadow-bs3 sm:static sm:shadow-none  absolute -right-10  z-10 sm:mt-6 mt-[30px] w-56   bg-white-A700  focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
    <div className="py-1" role="none">
    
      <Link to="/personaldetails" className="text-gray-700 block px-4 py-2 text-md" role="menuitem" tabIndex="-1" id="menu-item-0">Profile</Link>
      <Link to="/yourrides" className="text-gray-700 block px-4 py-2 text-md" role="menuitem" tabIndex="-1" id="menu-item-1">Your Rides</Link>
      <Link to="" className="text-gray-700 block px-4 py-2 text-md" role="menuitem" tabIndex="-1" id="menu-item-1">Inbox</Link>
      <Link to="" className="text-gray-700 block px-4 py-2 text-md" role="menuitem" tabIndex="-1" id="menu-item-1">Transfers</Link>
      <Link to="" className="text-gray-700 block px-4 py-2 text-md" role="menuitem" tabIndex="-1" id="menu-item-1">Payment</Link>
      <div onClick={openConfirmationDialog}  className="text-gray-700 block px-4 py-2 text-md cursor-pointer" role="menuitem" tabIndex="-1" id="menu-item-2">Log out</div>
      
    </div>
   <div className="hidden sm:flex sm:fixed right-0 sm:top-3">
    <svg onClick={() => setShowMenu(false)}
              id="close"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-7 h-7   sm:mr-4 cursor-pointer sm:flex hidden"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            </div>
  </div>
          </div>
            </div>
          </header>
         
         
          {showConfirmation && (
            <>
            <div className="fixed inset-0 bg-gray-800 opacity-50 z-50"></div>
        <dialog open className="fixed top-[50%] z-50 transform  -translate-y-1/2 rounded shadow-md">
        <div className="text-center">
          <h1 className="text-2xl text-left">Log Out</h1>
          <Line className="mt-6 h-[1px] bg-gray-300_72"  />
          <p className="text-lg mt-4 red">Are you sure you want to log out?</p>
          <div className="flex justify-evenly mt-6">
            <button  className=" bg-gray-300 p-3 px-6  rounded" onClick={closeConfirmationDialog}>Cancel</button>
            <button  className="bg-red-400 rounded p-3 px-6 text-white-A700_01" onClick={confirmLogout} >Log Out</button>
            
          </div>
        </div>
      </dialog>
      
      </>
      )}

    </>
  );
};

Header.defaultProps = {};

export default Header;
